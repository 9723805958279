import React from "react"

import Content from "../../components/content"
import Nav from "../../components/nav"

import { graphql, Link } from 'gatsby'
import { PostCard } from "../../components/post"
import { PageTitle } from "../../components/titles"
import Responsive from "../../components/responsive"
import styled from "styled-components"

const highlightsCount = 0;

const ArchivePostTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 5px;

`;

const ArchivePostSubtitle = styled.h4`
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 10px;
`

const ArchivePostExcerpt = styled.h4`
  font-size: 16px;
  font-weight: 200;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 50px;
`

const Writing = ({ data }) => {
  return (
    <div>
      <Nav current="blog"/>
      <Responsive comp={(big) => {
        return <Content style={{
          paddingLeft: "60px",
          paddingRight: "60px",
        }}>
          {/* <div>
            <PageTitle>Posts</PageTitle>
          </div> */}
          <div style={{
            display: big ? "flex" : undefined,
            flexWrap: "wrap"
          }}>
            {data.allMdx.edges.slice(0, highlightsCount).map(({ node }) => {
              return (
                <Link key={node.id} to={`/blog/${node.slug}`} style={{
                  textDecoration: "none",
                  width: big ? "calc(50% - 20px)" : undefined,
                  display: big ? "flex" : "block",
                  marginLeft: big ? "10px" : undefined,
                  marginRight: big ? "10px" : undefined,
                  verticalAlign: big && "top",
                  alignItems: big ? "stretch" : undefined,
                }}>
                  <PostCard post={node} inline smallTitle noShadow/>
                </Link>
              );
            })}
          </div>
          <div style={{
            marginTop: big && "15px",
            paddingTop: "20px",
            paddingLeft: big ? "10px" : undefined,
            paddingRight: big ? "10px" : undefined,
          }}>
            {data.allMdx.edges.slice(highlightsCount).map(({ node }) => {
              const date = new Date(node.frontmatter.date);
              return (
                <Link key={node.id} to={`/blog/${node.slug}`} style={{
                  textDecoration: "none",
                  color: "black",
                  display: "block",
                  padding: "15px",
                  borderRadius: "15px",
                  // border: "1px solid #a0a0a0",
                  marginBottom: "30px"
                }}>
                  <ArchivePostTitle>{node.frontmatter.title}</ArchivePostTitle>
                  <ArchivePostSubtitle>{new Intl.DateTimeFormat("default", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }).format(date)}</ArchivePostSubtitle>
                  <ArchivePostExcerpt>{(big ? node.excerptDesktop : node.excerptMobile).replace(" . ", ". ").replace(" , ", ", ")}</ArchivePostExcerpt>
                </Link>
              );
            })}
          </div>
        </Content>;
      }}/>
    </div>
  )
};

export default Writing;

export const query = graphql`
  query AllPosts {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          slug
          excerptDesktop: excerpt(pruneLength: 280)
          excerptMobile: excerpt(pruneLength: 210)
          frontmatter {
            title
            subtitle
            date
            author
            titleColor
            background
          }
        }
      }
    }
  }
`;

// titleImage {
//     childImageSharp {
//       gatsbyImageData(layout: FULL_WIDTH)
//     }
//   }
